<template>
  <div class="p-4 md:px-8">
    <Breadcrumb :links="breadcrumb" />
    <div class="grid grid-cols-12 gap-4 my-3">
      <div class="col-span-12 md:col-span-8">
        <div class="rounded overflow-hidden shadow-lg">
            <div class="bg-blue-100 px-4 py-3">
              <h1 class="text-lg font-semibold text-blue-700">
                Configuração de ChatBot
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 256 256" class="inline ml-1 h-7 w-7 mb-1">
                  <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-24,16v24H116V152ZM80,164a12,12,0,0,1,12-12h8v24H92A12,12,0,0,1,80,164Zm84,12h-8V152h8a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z">
                  </path>
                </svg>
              </h1>
            </div>
            <div class="px-4 py-4">
              <p class="text-base_2 font-medium text-gray-900">Um <b>chatbot de WhatsApp</b> é um assistente virtual que responde automaticamente a mensagens, ajudando a melhorar a <b>interação com clientes</b></p>
              <p class="text-base_2 font-medium text-gray-900 mt-2">A tela a seguir permite <b>configurar o chatbot</b>, incluindo definições de respostas automáticas, horários de funcionamento e outros parâmetros. Com essas configurações, o chatbot poderá responder às perguntas dos usuários de forma <b>eficiente, 24 horas por dia</b>.</p>
              <label for="habilitarChatbot" class="flex items-center text-base_2 font-medium text-gray-900 mt-3">
                <input v-model="form.habilitarChatbot" type="checkbox" class="rounded-sm" id="habilitarChatbot" />
                <span class="ml-2 text-base">Habilitar chatbot em caso de resposta receptivo.</span>
              </label>
            </div>
        </div>
      </div>
    </div>

    <span v-if="form.habilitarChatbot" class="text-gray-400 font-normal text-sm_3">(campos com <b class="text-red-500 font-normal">*</b> são obrigatórios)</span>

    <div v-if="form.habilitarChatbot" class="grid grid-cols-12 gap-5 mt-2">
      <div class="col-span-12 sm:col-span-6 md:col-span-4 2xl:col-span-3">
        <h2 class="text-base font-semibold mb-3"><b>·</b> Texto inicial <b class="text-red-500 font-normal">*</b></h2>
        <textarea rows="5" type="text" v-model="form.textoInicial" class="mb-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
        
        <hr class="my-4"/>
        <h2 class="text-base font-semibold mb-3"><b>·</b> Opções do Menu <b class="text-red-500 font-normal">*</b></h2>
        <button @click="addOpcao" type="button" class="inline-flex justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm_3 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
          Adicionar Opção
        </button>
        
        <div v-for="(item, idx) in form.opcoes" :key="idx" class="py-3 px-4 bg-gray-100 rounded-xl mb-3 relative">
          
          <button @click="removerOpcao(idx)" type="button" v-tooltip="'Remover opção'" class="absolute top-2 right-3 mb-1 text-red-500 hover:text-red-600 focus:text-red-700 focus:shadow-sm focus:ring-opacity-50 rounded-md text-sm font-semibold text-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 256 256" class="h-6 w-6">
              <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208ZM165.66,101.66,139.31,128l26.35,26.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32Z"/>
            </svg>
          </button>

          <div class="grid grid-cols-12 gap-3">
            <div class="col-span-12">
              <label class="block text-sm font-medium mb-2">Item opção <b class="text-red-500 font-normal">*</b></label>
              <input maxlength="40" v-model="item.nome" type="text" class="w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>

            <div class="col-span-12">
              <label class="block text-sm font-medium">Ação de resposta ao selecionar <b class="text-red-500 font-normal">*</b></label>
              <v-select v-model="item.tipo" @input="trocaOpcao(idx)" name="tipo" :options="opcoesDeTipo" :clearable="false" id="tipo" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>

            <div v-if="item.tipo === 'Texto'" class="col-span-12">
              <hr class="my-2" />
              <label class="block text-sm font-medium">Texto <b class="text-red-500 font-normal">*</b></label>
              <textarea rows="3" type="text" :required="true" v-model="item.texto" class="mb-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
            </div>

            <div v-if="item.tipo === 'Iniciar atendimento'" class="col-span-12">
              <hr class="my-2" />
              <label class="block text-sm font-medium">Centro de custo do operador <b class="text-red-500 font-normal">*</b></label>
              <v-select name="tipo" label="nome" v-model="item.centrocusto" :options="centrosDeCusto" :reduce="(c) => c._id" :required="true" :clearable="false" id="tipo" class="mb-2 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>

            <div v-if="item.tipo === 'URL'" class="col-span-12">
              <hr class="my-2" />
              <label class="block text-sm font-medium">URL <b class="text-red-500 font-normal">*</b></label>
              <input v-model="item.url" :required="true" type="text" class="mb-2 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>

            <div v-if="item.tipo === 'Arquivo'" class="col-span-12">
              <hr class="my-2" />
              <label class="block text-sm font-medium">Arquivo <b class="text-red-500 font-normal">*</b></label>
              <vueDropzone :required="true" :key="keyDropZoneArquivo" ref="arquivo" id="arquivo" class="w-full" @vdropzone-sending="send" :options="dropzoneAttachmenOptionsArquivo" v-on:vdropzone-success="(file, response) => updateArquivo(file, response, idx, 'arquivo', false)" v-on:vdropzone-removed-file="removeArquivo(idx)" />
            </div>

            <div v-if="item.tipo === 'Imagem'" class="col-span-12">
              <hr class="my-2" />
              <label class="block text-sm font-medium">Imagem <b class="text-red-500 font-normal">*</b></label>
              <vueDropzone :required="true" :key="keyDropZoneImagem" ref="imagem" id="imagem" class="w-full" @vdropzone-sending="send" :options="dropzoneAttachmenOptionsImagem" v-on:vdropzone-success="(file, response) => updateArquivo(file, response, idx, 'imagem', false)" v-on:vdropzone-removed-file="removeArquivo(idx)"/>
            </div>

            <div v-if="item.tipo === 'Novas Opções'" class="col-span-12">
              <hr class="mb-3" />
              <label class="block text-base_2 font-medium mb-2">Faça um novo submenu <b class="text-red-500 font-normal">*</b></label>
              <label class="block text-sm font-medium mb-2">Texto Inicial <b class="text-red-500 font-normal">*</b></label>
              <textarea rows="3" type="text" v-model="item.textoInicial" class="mb-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>

              <button @click="addSubmenu(idx)" class="inline-flex justify-center my-2 py-2 px-4 border border-transparent shadow-sm text-sm_3 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"    >
                Adicionar opção
              </button>

              <label class="block text-center font-medium">Opções do submenu</label>
              <div v-for="(opcao, idxSubmenu) in item.submenus" :key="idxSubmenu" class="px-2 bg-gray-100 rounded-xl mb-2 relative">
                <hr class="my-2" />
                <label class="block text-sm font-medium">Item opção <b class="text-red-500 font-normal">*</b></label>
                <input maxlength="40" v-model="opcao.nome" type="text" class="w-full focus:ring-indigo-500 focus:border-indigo-500 mb-2 block shadow-sm sm:text-sm border-gray-300 rounded-md" />

                <label class="block text-sm font-medium">Ação de resposta ao selecionar <b class="text-red-500 font-normal">*</b></label>
                <v-select v-model="opcao.tipo" @input="trocaOpcaoSubmenu(idx, idxSubmenu)" name="tipo" :options="opcoesDeTipo" :clearable="false" id="tipo" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 mb-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>

                <div v-if="opcao.tipo === 'Texto'" class="col-span-12">
                  <label class="block text-sm font-medium">Texto <b class="text-red-500 font-normal">*</b></label>
                  <textarea rows="3" type="text" :required="true" v-model="opcao.texto" class="mb-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
                </div>

                <div v-if="opcao.tipo === 'URL'" class="col-span-12">
                  <label class="block text-sm font-medium">URL <b class="text-red-500 font-normal">*</b></label>
                  <input v-model="opcao.url" :required="true" type="text" class="mb-2 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

                <div v-if="opcao.tipo === 'Arquivo'" class="col-span-12">
                  <label class="block text-sm font-medium">Arquivo <b class="text-red-500 font-normal">*</b></label>
                  <vueDropzone :required="true" :key="keyDropZoneArquivo" ref="arquivo" id="arquivo" class="w-full" @vdropzone-sending="send" :options="dropzoneAttachmenOptionsArquivo" v-on:vdropzone-success="(file, response) => updateArquivo(file, response, idx, 'arquivo', true)" v-on:vdropzone-removed-file="removeArquivo(idx)" />
                </div>

                <div v-if="opcao.tipo === 'Imagem'" class="col-span-12">
                  <label class="block text-sm font-medium">Imagem <b class="text-red-500 font-normal">*</b></label>
                  <vueDropzone :required="true" :key="keyDropZoneImagem" ref="imagem" id="imagem" class="w-full" @vdropzone-sending="send" :options="dropzoneAttachmenOptionsImagem" v-on:vdropzone-success="(file, response) => updateArquivo(file, response, idx, 'imagem', true)" v-on:vdropzone-removed-file="removeArquivo(idx)" />
                </div>

                <div v-if="opcao.tipo === 'Iniciar atendimento'" class="col-span-12">
                  <label class="block text-sm font-medium">Centro de custo do operador <b class="text-red-500 font-normal">*</b></label>
                  <v-select name="tipo" label="nome" v-model="opcao.centrocusto" :options="centrosDeCusto" :reduce="(c) => c._id" :required="true" :clearable="false" id="tipo" class="mb-2 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

                <button
                  @click="removerOpcaoSubmenu(idx, idxSubmenu)"
                  type="button"
                  class="mt-2 text-red-500 hover:text-red-600 focus:text-red-700 focus:shadow-sm focus:ring-opacity-50 rounded-md text-sm font-semibold text-center"
                >
                  Remover opção do submenu
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Arvore :textoInicial="form.textoInicial" :opcoes="form.opcoes" />
    </div>
    <div class="md:h-espaco"></div>
    <div class="text-center md:border-t md:shadow-lg md:fixed md:bottom-0 w-full py-3 bg-white z-10 md:left-0 md:right-0">
      <p class="text-zinc-700 font-normal text-base_2">
        Após clicar em <b class="text-blue-600">Salvar configurações</b>, seu chatbot será
        <b :class="{ 'text-green-700': form.habilitarChatbot, 'text-red-700': !form.habilitarChatbot }">{{ form.habilitarChatbot ? "habilitado" : "desabilitado" }}</b>.
      </p>
      <p class="text-gray-400 text-sm font-medium mt-2">Confira todas as informações antes de salvar seu chatbot.</p>
      <button @click="save" :disabled="!checaFormOk()" :class="checaFormOk() ? 'hover:bg-blue-700 active:bg-blue-600 cursor-pointer' : 'opacity-30 cursor-not-allowed'" class="bg-blue-600 text-white text-center w-full md:w-1/4 xl:w-1/6 text-base p-2 rounded-md mt-4 mb-2">
        Salvar configurações
      </button>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Breadcrumb from "../../components/Breadcrumbs.vue";
import vueDropzone from "vue2-dropzone";
import Arvore from "./Arvore.vue";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    Breadcrumb,
    vueDropzone,
    Arvore
  },
  created() {
    this.dropzoneAttachmenOptionsArquivo = this.$http.getDropzoneConfig(`upload`, "post", { ...this.dropzoneBodyArquivo });
    this.dropzoneAttachmenOptionsImagem = this.$http.getDropzoneConfig(`upload`, "post", { ...this.dropzoneBodyImagem });
  },
  data() {
    return {
      breadcrumb: [{ url: "/config", value: "Configuração" }],
      form: {
        textoInicial: "",
        opcoes: [{ nome: "Conheça nosso Site", tipo: "URL" }],
        habilitarChatbot: false
      },
      centrosDeCusto: [],
      keyDropZoneArquivo: 0,
      keyDropZoneImagem: 1,
      opcoesDeTipo: ["Texto", "URL", "Arquivo", "Imagem", "Novas Opções", "Iniciar atendimento"],
      dropzoneBodyImagem: {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: "Clique ou arraste a sua imagem aqui",
        dictRemoveFile: "Remover",
        acceptedFiles: ".png,.jpg"
      },
      dropzoneBodyArquivo: {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: "Clique ou arraste o seu arquivo aqui",
        dictRemoveFile: "Remover",
        acceptedFiles: ".pdf,.DOCX"
      },
      dropzoneAttachmenOptionsArquivo: this.$http.getDropzoneConfig(`upload`, "post", { ...this.dropzoneBodyArquivo }),
      dropzoneAttachmenOptionsImagem: this.$http.getDropzoneConfig(`upload`, "post", { ...this.dropzoneBodyImagem })
    };
  },
  methods: {
    addSubmenu(idx) {
      if (!this.form.opcoes[idx].submenus) {
        Vue.set(this.form.opcoes[idx], "submenus", []);
      }

      this.form.opcoes[idx].submenus.push({ nome: "Nova Opção", tipo: "Texto" });
    },

    checaFormOk() {
      return this.form.habilitarChatbot && this.form.textoInicial.trim() !== "" && this.form.opcoes.length > 0 && this.validaOpcoes(this.form.opcoes);
    },

    validaOpcoes(opcoes) {
      if (!opcoes || !opcoes.length || opcoes.length === 0) {
        return false;
      }

      return opcoes.every((opcao) => opcao.texto || opcao.url || opcao.arquivo || opcao.imagem || opcao.centrocusto || (opcao.submenus && this.validaOpcoes(opcao.submenus)));
    },

    addOpcao() {
      if (this.form.opcoes.length === 5) {
        return this.$vToastify.error("Limite de opções atingido");
      }

      this.form.opcoes.push({ nome: "TEXTO DA OPÇÃO", tipo: "Texto" });
    },
    removerOpcao(idx) {
      this.form.opcoes.splice(idx, 1);
    },

    removerOpcaoSubmenu(idx, submenuIdx) {
      this.form.opcoes[idx].submenus.splice(submenuIdx, 1);
    },
    trocaOpcao(idx) {
      const opcao = this.form.opcoes[idx];

      delete opcao.texto;
      delete opcao.arquivo;
      delete opcao.imagem;
      delete opcao.url;
      delete opcao.submenus;
      delete opcao.centrocusto;

      this.form.opcoes[idx] = opcao;
    },

    trocaOpcaoSubmenu(idx, submenuIdx) {
      const opcao = this.form.opcoes[idx].submenus[submenuIdx];

      delete opcao.texto;
      delete opcao.arquivo;
      delete opcao.imagem;
      delete opcao.url;
      delete opcao.submenus;
      delete opcao.centrocusto;

      this.form.opcoes[idx].submenus[submenuIdx] = opcao;
    },

    send(file, xhr, formData) {
      formData.append("folder", "arquivosWhats");
    },

    updateArquivo(file, response, idx, tipo, submenu) {
      if (submenu) {
        const arquivo = response.file;
        const opcao = this.form.opcoes.find((opcao) => opcao.tipo === "Novas Opções" && opcao.submenus && opcao.submenus[idx]);

        if (!opcao) {
          return;
        }

        const submenu = opcao.submenus[idx];

        Vue.set(submenu, tipo, {
          url: `${this.api}/upload?mimetype=${arquivo.mimetype}&filename=${arquivo.filename}&folder=arquivosWhats`,
          filename: arquivo.originalname
        });

        Vue.set(opcao.submenus, idx, submenu);

        return;
      }

      const arquivo = response.file;
      const opcao = this.form.opcoes[idx];

      opcao[tipo] = {
        url: `${this.api}/upload?mimetype=${arquivo.mimetype}&filename=${arquivo.filename}&folder=arquivosWhats`,
        filename: arquivo.originalname
      };

      this.form.opcoes[idx] = opcao;
    },

    removeArquivo(idx) {
      const opcao = this.form.opcoes[idx];
      delete opcao.url;
      delete opcao.filename;
    },

    replaceCampo(campo) {
      if (campo === "opcoes") return "Opções do Menu";

      return campo.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
    },

    validaFormulario() {
      if (!this.form.habilitarChatbot) {
        return { mensagemDeErro: "", existemErros: false };
      }

      let mensagemDeErro = "Campos inválidos:";
      let existemErros = false;

      if (this.form.textoInicial.trim().length === 0) {
        return { mensagemDeErro: "O chatbot precisa ter uma mensagem inicial!", existemErros: true };
      }

      if (!this.form.opcoes || !this.form.opcoes.length) {
        return { mensagemDeErro: "O chatbot precisa ter opções de interação", existemErros: true };
      }

      Object.keys(this.form).forEach((campo) => {
        if (this.form[campo] === null || (typeof this.form[campo] === "string" && this.form[campo].trim().length === 0) || this.form[campo].length === 0) {
          existemErros = true;
          mensagemDeErro += `${this.replaceCampo(campo)} é um campo obrigatório! `;
        }
      });

      const opcoesComErro = this.form.opcoes.filter(
        (opcao) =>
          !opcao.arquivo && !opcao.imagem && (!opcao.url || opcao.url.trim().length === 0) && (!opcao.texto || opcao.texto.trim().length === 0) && !opcao.centrocusto && (!opcao.submenus || !opcao.submenus.length)
      );

      if (opcoesComErro.length > 0) {
        opcoesComErro.forEach((opcao) => {
          existemErros = true;
          mensagemDeErro += `A opção "${opcao.nome}" não foi preenchida! `;
        });
      }

      return { mensagemDeErro, existemErros };
    },

    async getCentroCusto() {
      const req = await this.$http.post("/v1/centrocusto/list", { all: true });
      this.centrosDeCusto = req.data.data;
    },

    async save() {
      console.log(this.form);
      if (this.form.habilitarChatbot) {
        const { mensagemDeErro, existemErros } = this.validaFormulario();

        if (existemErros) {
          return this.$vToastify.error(mensagemDeErro);
        }

        // const method = this.form._id ? "put" : "post";
        // const url = this.form._id ? `/v1/chatbot/${this.form._id}` : "/v1/chatbot";
        // const req = await this.$http[method](url, this.form);

        // if (req.data.success) {
        //   return this.$vToastify.success("Salvo com sucesso!");
        // }

        // return this.$vToastify.error(req.data.err);
      }

      if (this.form._id) {
        const req = await this.$http.delete(`/v1/chatbot/${this.form._id}`);

        if (req.data.success) {
          return this.$vToastify.success("Chatbot desativado com sucesso!");
        }

        return this.$vToastify.error(req.data.err);
      }
    },

    async start() {
      const req = await this.$http.get(`/v1/chatbot/usuario/me`);

      if (req.data.chatbot) {
        this.form = { ...req.data.chatbot, opcoes: req.data.chatbot.menu.opcoes };
      }
    }
  },
  async beforeMount() {
    await Promise.all([this.start(), this.getCentroCusto()]);
  }
};
</script>
