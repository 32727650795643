<template>
  <div class="col-span-12 sm:col-span-6 md:col-span-8 2xl:col-span-9 bg-gray-50 p-5 rounded-lg overflow-x-auto">
    <h2 class="text-center text-xl font-bold">Árvore do fluxo</h2>
    <div v-if="textoInicial.trim() !== ''" class="relative mb-2 rounded-md bg-white font-semibold p-5 w-full md:w-2/3 mx-auto">
      <p class="text-center break-words" v-html="getHtmlFrom(textoInicial)"></p>
      <!-- <div class="absolute -bottom-5 left-1/2 transform -translate-x-1/2 h-5 w-1 bg-gray-300" :class="{ hidden: opcoes.length === 0 }"></div> -->
    </div>
    <!-- <div class="h-1 bg-gray-300 mt-4" :class="{ hidden: opcoes.length === 0 || textoInicial.trim() === '', 'w-screen': opcoes.length > 4 }"></div> -->

    <div class="flex items-center gap-2 mt-6" v-if="opcoes.length > 0 && textoInicial.trim() !== ''">
      <div v-for="(opcao, idx) in opcoes" :key="idx" class="relative">
        <!-- <div class="absolute -top-6 left-1/2 transform -translate-x-1/2 w-1 h-6 bg-gray-300"></div> -->
        <p class="rounded-md bg-white font-semibold p-3 break-words flex whitespace-nowrap items-center gap-2" :class="{ 'bg-green-100': opcao.tipo === 'Novas Opções' }">
          <span v-if="opcao.tipo === 'Arquivo'">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000000" viewBox="0 0 256 256">
              <path
                d="M52,144H36a8,8,0,0,0-8,8v56a8,8,0,0,0,8,8H52a36,36,0,0,0,0-72Zm0,56H44V160h8a20,20,0,0,1,0,40Zm169.53-4.91a8,8,0,0,1,.25,11.31A30.06,30.06,0,0,1,200,216c-17.65,0-32-16.15-32-36s14.35-36,32-36a30.06,30.06,0,0,1,21.78,9.6,8,8,0,0,1-11.56,11.06A14.24,14.24,0,0,0,200,160c-8.82,0-16,9-16,20s7.18,20,16,20a14.24,14.24,0,0,0,10.22-4.66A8,8,0,0,1,221.53,195.09ZM128,144c-17.65,0-32,16.15-32,36s14.35,36,32,36,32-16.15,32-36S145.65,144,128,144Zm0,56c-8.82,0-16-9-16-20s7.18-20,16-20,16,9,16,20S136.82,200,128,200ZM48,120a8,8,0,0,0,8-8V40h88V88a8,8,0,0,0,8,8h48v16a8,8,0,0,0,16,0V88a8,8,0,0,0-2.34-5.66l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40v72A8,8,0,0,0,48,120ZM160,51.31,188.69,80H160Z"
              />
            </svg>
          </span>
          <span v-if="opcao.tipo === 'Imagem'">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000000" viewBox="0 0 256 256">
              <path
                d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,16V158.75l-26.07-26.06a16,16,0,0,0-22.63,0l-20,20-44-44a16,16,0,0,0-22.62,0L40,149.37V56ZM40,172l52-52,80,80H40Zm176,28H194.63l-36-36,20-20L216,181.38V200ZM144,100a12,12,0,1,1,12,12A12,12,0,0,1,144,100Z"
              />
            </svg>
          </span>
          <span v-if="opcao.tipo === 'Texto'">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000000" viewBox="0 0 256 256">
              <path
                d="M88,104a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,104Zm8,40h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16ZM232,56V184a16,16,0,0,1-16,16H155.57l-13.68,23.94a16,16,0,0,1-27.78,0L100.43,200H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56Zm-16,0H40V184h65.07a8,8,0,0,1,7,4l16,28,16-28a8,8,0,0,1,7-4H216Z"
              />
            </svg>
          </span>
          <span v-if="opcao.tipo === 'URL'">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000000" viewBox="0 0 256 256">
              <path
                d="M240,88.23a54.43,54.43,0,0,1-16,37L189.25,160a54.27,54.27,0,0,1-38.63,16h-.05A54.63,54.63,0,0,1,96,119.84a8,8,0,0,1,16,.45A38.62,38.62,0,0,0,150.58,160h0a38.39,38.39,0,0,0,27.31-11.31l34.75-34.75a38.63,38.63,0,0,0-54.63-54.63l-11,11A8,8,0,0,1,135.7,59l11-11A54.65,54.65,0,0,1,224,48,54.86,54.86,0,0,1,240,88.23ZM109,185.66l-11,11A38.41,38.41,0,0,1,70.6,208h0a38.63,38.63,0,0,1-27.29-65.94L78,107.31A38.63,38.63,0,0,1,144,135.71a8,8,0,0,0,16,.45A54.86,54.86,0,0,0,144,96a54.65,54.65,0,0,0-77.27,0L32,130.75A54.62,54.62,0,0,0,70.56,224h0a54.28,54.28,0,0,0,38.64-16l11-11A8,8,0,0,0,109,185.66Z"
              />
            </svg>
          </span>
          <span v-if="opcao.tipo === 'Novas Opções'">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000000" viewBox="0 0 256 256">
              <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
            </svg>
          </span>
          <span v-if="opcao.tipo === 'Iniciar atendimento'">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000000" viewBox="0 0 256 256">
              <path
                d="M230.92,212c-15.23-26.33-38.7-45.21-66.09-54.16a72,72,0,1,0-73.66,0C63.78,166.78,40.31,185.66,25.08,212a8,8,0,1,0,13.85,8c18.84-32.56,52.14-52,89.07-52s70.23,19.44,89.07,52a8,8,0,1,0,13.85-8ZM72,96a56,56,0,1,1,56,56A56.06,56.06,0,0,1,72,96Z"
              />
            </svg>
          </span>
          {{ opcao.nome }}
        </p>
      </div>
    </div>
    <div v-if="(opcoes.length === 0 && textoInicial.trim() === '') || textoInicial.trim() === ''" class="text-center mt-10">
      <h3 class="text-gray-400 font-semibold">Ops, parece que ainda não fez um texto inicial e adicionou uma opção {{ ":(" }}</h3>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    textoInicial: String,
    opcoes: Array
  },
  methods: {
    getHtmlFrom(str) {
      if (str) {
        return str
          .replace(/(?:\*)([^*]*)(?:\*)/gm, "<strong>$1</strong>")
          .replace("{#", "<b>Contato: </b>")
          .replace("#}", "")
          .replace(/(?:_)([^_]*)(?:_)/gm, "<i>$1</i>")
          .replace(/(?:~)([^~]*)(?:~)/gm, "<strike>$1</strike>")
          .replace(/\n/gim, "<br/>")
          .replace(/(?:```)([^```]*)(?:```)/gm, "<tt>$1</tt>");
      }

      return str;
    }
  }
};
</script>
